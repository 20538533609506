import {
  API_ATTRIBUTE_LIST,
  API_QUERY_KEY_OPENAPI,
  buildRequestApi,
  HTTP_METHOD,
  queryClient,
} from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { useRouterParams } from '@shared/hooks'
import { useMutation } from '@tanstack/react-query'

export const useRemoveAttributeFromGroup = () => {
  const { hasParam, getParamValues } = useRouterParams()

  const isDisabledButton = !hasParam('selected')

  const mutation = useMutation({
    mutationFn: (id: number) => {
      const removeObjectGroup = buildRequestApi<ApiAttribute>(
        `${API_ATTRIBUTE_LIST}${id}/`,
        'Ошибка удаления атрибута из группы',
        {
          method: HTTP_METHOD.PATCH,
          body: {
            object_group: null,
          },
        },
      )

      return removeObjectGroup()
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: API_QUERY_KEY_OPENAPI.GET_ATTRIBUTE_LIST,
      })
    },
  })

  const removeFromGroup = () => {
    getParamValues('selected').map((attributeId) => {
      return mutation.mutate(parseInt(attributeId))
    })
  }

  const isPending = mutation.isPending

  return {
    isDisabledButton,
    isPending,
    removeFromGroup,
  }
}
