import { addAttributeApi } from '@entities/attributes'
import { API_ATTRIBUTE_TYPE, API_QUERY_KEY_OPENAPI, queryClient } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { type AddAttributeFormInputs, type UseAddAttributeArgs } from './types'

const useAddAttribute = ({ onClose }: UseAddAttributeArgs) => {
  const methods = useForm<AddAttributeFormInputs>({
    defaultValues: { type: API_ATTRIBUTE_TYPE.TEXT },
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const mutation = useMutation({
    mutationFn: async ({ name, type, group }: AddAttributeFormInputs) => {
      const newAttribute = await addAttributeApi({
        name: name,
        attribute_type: type,
        object_group: group ? Number(group) : undefined,
      })

      return newAttribute
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY_OPENAPI.GET_ATTRIBUTE_LIST })
      onClose()
    },
  })

  const addAttribute = methods.handleSubmit((fieldsData) => {
    mutation.mutate(fieldsData)
  })

  return {
    handleModalClose,
    methods,
    addAttribute,
    isLoading: mutation.isPending,
  }
}

export { useAddAttribute }
