import { useRouterParams } from '@shared/hooks'
import { type HandleFilterChange } from '@shared/ui'
import { useState } from 'react'

import { type UseAttributesFilterArgs } from './types'

const useAttributesFilter = ({ param, options }: UseAttributesFilterArgs) => {
  const { deleteParam, hasParam, getParamValues, removeParamValue, addParamValue } =
    useRouterParams()

  const [popoverOptions, setPopoverOptions] = useState(options)

  const handleFilterChange: HandleFilterChange<typeof popoverOptions> = (
    updatedOptions,
    _event,
    clickedOption,
  ) => {
    setPopoverOptions(updatedOptions)

    if (clickedOption.checked) {
      addParamValue(param, clickedOption.data.id)
    } else {
      removeParamValue(param, clickedOption.data.id)
    }
  }

  const handleFilterReset = () => {
    const uncheckedOptions = popoverOptions.map(({ label, data: { id } }) => {
      return { label, data: { id } }
    })

    setPopoverOptions(uncheckedOptions)

    deleteParam(param)
  }

  const checkParams = () => {
    if (hasParam(param)) {
      const checkedItems = getParamValues(param)

      const actualOptions = popoverOptions.map(({ label, data: { id } }) => {
        const option = { label, data: { id } }

        if (checkedItems.includes(id.toString())) {
          return { ...option, checked: 'on' }
        }

        return option
      })

      setPopoverOptions(actualOptions)
    }
  }

  return {
    popoverOptions,
    handleFilterChange,
    handleFilterReset,
    checkParams,
  }
}

export { useAttributesFilter }
