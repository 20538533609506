import { EuiForm } from '@elastic/eui'
import {
  useAddAttribute,
  useAttributeGroupOptions,
  useAttributeTypeOptions,
} from '@entities/attributes'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { type AddAttributeProps } from './types'

const AddAttribute: ReactFc<AddAttributeProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, addAttribute, isLoading } = useAddAttribute({ onClose })

  const { groupOptions, isLoading: isGroupOptionsLoading } = useAttributeGroupOptions()
  const { typeOptions, isLoading: isTypeOptionsLoading } = useAttributeTypeOptions()

  return (
    <FormProvider {...methods}>
      <Modal
        title="Новый атрибут"
        onClose={handleModalClose}
        onSubmit={addAttribute}
        isOpen={isOpen}
        isLoading={isLoading}
      >
        <EuiForm component="form">
          <TextInput
            name="name"
            placeholder="Название атрибута"
            registerOptions={{ required: 'Введите название' }}
            inputProps={{
              isLoading: isLoading,
            }}
            isRequired
          />

          <SelectInput
            name="group"
            placeholder="Выбрать группу"
            label="Группы"
            inputProps={{ options: groupOptions, isLoading: isGroupOptionsLoading }}
          />

          <SelectInput
            name="type"
            placeholder="Выбрать тип"
            label="Тип атрибута"
            inputProps={{ options: typeOptions, isLoading: isTypeOptionsLoading }}
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddAttribute }
