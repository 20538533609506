import { ATTRIBUTE_FLAGS } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ATTRIBUTE_LABELS } from '@shared/config'

import { AttributeName } from './attribute-name'

const renderAttributeName: RenderColumn<ApiAttribute, 'name'> = (name, attribute) => {
  const getLabel = (attribute: ApiAttribute): string => {
    if (attribute[ATTRIBUTE_FLAGS.NAME]) {
      return ATTRIBUTE_LABELS.NAME
    }

    if (attribute[ATTRIBUTE_FLAGS.BRAND]) {
      return ATTRIBUTE_LABELS.BRAND
    }

    if (attribute[ATTRIBUTE_FLAGS.SKU]) {
      return ATTRIBUTE_LABELS.SKU
    }

    if (attribute[ATTRIBUTE_FLAGS.PREVIEW]) {
      return ATTRIBUTE_LABELS.PREVIEW
    }

    return ''
  }

  return <AttributeName name={name} label={getLabel(attribute)} />
}

export { renderAttributeName }
