import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  transparentize,
  useEuiTheme,
} from '@elastic/eui'
import { ATTRIBUTE_LABELS } from '@shared/config'

import { type AttributeNameProps } from './types'

const AttributeName: ReactFc<AttributeNameProps> = ({ name, label: labelExternal }) => {
  const { euiTheme } = useEuiTheme()

  const renderLabel = (label: string) => {
    if (label === ATTRIBUTE_LABELS.PREVIEW) {
      return <EuiIcon type="image" size="s" />
    }

    if (label) {
      return <EuiBadge color={transparentize(euiTheme.colors.warning, 0.3)}>{label}</EuiBadge>
    }

    return null
  }

  return (
    <EuiFlexGroup alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false}>{name}</EuiFlexItem>

      {renderLabel(labelExternal)}
    </EuiFlexGroup>
  )
}

export { AttributeName }
