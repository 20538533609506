import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { AddAttributeToGroup, RemoveAttributeFromGroup } from '@features/attributes'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>{/* TODO: Добавить поиск по группам */}</EuiHeaderSection>

      <EuiHeaderSectionItem>
        <RemoveAttributeFromGroup />
        <AddAttributeToGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
