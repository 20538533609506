import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { NumberInput, TextInput } from '@shared/ui/form'

import { type AttributeFormFieldProps } from './types'

const AttributeFormField: ReactFc<AttributeFormFieldProps> = ({
  isLoading,
  attribute_type: attributeType,
  name: attributeName,
  value,
  is_required: isRequired,
  is_editable: isEditable,
}) => {
  const requiredRegisterOptions = isRequired && { required: MESSAGE.ATTRIBUTE_EMPTY_ERROR }

  const registerOptions = {
    ...requiredRegisterOptions,
  }

  switch (attributeType) {
    case API_ATTRIBUTE_TYPE.TEXT:
      return (
        <TextInput
          name="value"
          placeholder={attributeName}
          registerOptions={registerOptions}
          inputProps={{
            defaultValue: value,
            isLoading: isLoading,
            required: isRequired,
            disabled: !isEditable,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.NUMBER:
      return (
        <NumberInput
          name="value"
          placeholder={attributeName}
          registerOptions={registerOptions}
          inputProps={{
            defaultValue: value,
            isLoading: isLoading,
            required: isRequired,
            disabled: !isEditable,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.LIST:
      return null
    case API_ATTRIBUTE_TYPE.BOOL:
      return null
    case API_ATTRIBUTE_TYPE.NESTED_LIST:
      return null
    default:
      return null
  }
}

export { AttributeFormField }
