import { useUserSettingsStore } from '@entities/user'

import { type DragEndParams, type UseSkuDraggableListReturn } from './types'
import { useFeaturedGroupsList } from './use-featured-groups-list'

export const useSkuDraggableList = (): UseSkuDraggableListReturn => {
  const attributeOrder = useUserSettingsStore.use.attributeOrder()
  const setAttributeOrder = useUserSettingsStore.use.setAttributeOrder()

  const featuredGroupsList = useFeaturedGroupsList().sort((prev, next) => {
    return attributeOrder.indexOf(prev.order) - attributeOrder.indexOf(next.order)
  })

  const onDragEnd = ({ source, destination }: DragEndParams) => {
    if (!source || !destination) return

    const reorderedItems = Array.from(featuredGroupsList)
    const [removed] = reorderedItems.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)

    const newOrder = reorderedItems.map((group) => {
      return group.order
    })

    setAttributeOrder(newOrder)
  }

  return [featuredGroupsList, onDragEnd]
}
