import { type GetFilterOptions } from './types'
import { useAttributeGroupOptions } from './use-attribute-group-options'
import { useAttributeTypeOptions } from './use-attribute-type-options'

const useAttributeFilterOptions = () => {
  const { typeOptions } = useAttributeTypeOptions()
  const { groupOptions } = useAttributeGroupOptions()

  const getFilterOptions: GetFilterOptions = (options) => {
    return options.map(({ value, inputDisplay }) => {
      return {
        label: inputDisplay!.toString(),
        data: {
          id: value.toString(),
        },
      }
    })
  }

  const filterTypeOptions = getFilterOptions(typeOptions)
  const filterGroupOptions = getFilterOptions(groupOptions)

  return { typeOptions: filterTypeOptions, groupOptions: filterGroupOptions }
}

export { useAttributeFilterOptions }
