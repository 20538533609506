import { getCookie } from '@shared/lib'

export const API_BASE = '/backend/api/portal/rest'

export const API_LOGIN = '/login/'
export const API_LOGOUT = '/logout/'

export const API_ATTRIBUTE_LIST = '/attributes/'
export const API_ATTRIBUTE_VALUES = '/attribute_values/'
export const API_ATTRIBUTE_TYPES_LIST = '/attribute_types/'
export const API_OBJECT_GROUP_LIST = '/object_groups/'
export const API_CATEGORY_LIST = '/categories/'
export const API_SKU_LIST = '/skus/'

export const DEFAULT_HEADERS: HeadersInit = {
  'Content-Type': 'application/json',
  'X-CSRFToken': getCookie('csrftoken') || '',
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum API_ATTRIBUTE_MODEL {
  SKU = 10,
  CATEGORY = 20,
  OBJECT_GROUP = 30,
}

export enum API_ATTRIBUTE_TYPE {
  TEXT = 10,
  LIST = 20,
  BOOL = 30,
  NESTED_LIST = 40,
  NUMBER = 50,
  FILE = 60,
}

export enum API_OBJECT_GROUP {
  SKU = 1,
  CATEGORY = 2,
  FILE_UPLOAD = 3,
  ATTRIBUTE = 4,
}

export enum API_QUERY_KEY {
  ATTRIBUTE_LIST = 'attribute-list',
  ATTRIBUTE_SKU_LIST = 'attribute-sku-list',
  ATTRIBUTE_GROUP_LIST = 'attribute-group-list',
  CATALOG_LIST = 'catalogs-list',
  CATEGORY_LIST = 'categories-list',
  SKU_LIST = 'sku-list',
}

// TODO: Переименовать, когда всё будет на OPENAPI
export const API_QUERY_KEY_OPENAPI = {
  GET_ATTRIBUTE_LIST: ['get', '/attributes/'],
}

export enum ATTRIBUTE_FLAGS {
  SKU = 'is_sku',
  NAME = 'is_name',
  BRAND = 'is_brand',
  PREVIEW = 'is_preview',
}
