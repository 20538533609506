import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { AddAttribute } from '@features/attributes'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

const AttributeActions: ReactFc = () => {
  const {
    isOpen: isAddAttributeModalOpen,
    handleOpenDialog: showAddAttributeModal,
    handleCloseDialog: closeAddAttributeModal,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: 'Новый атрибут',
          icon: 'plus',
          onClick: showAddAttributeModal,
        },
        {
          name: 'Привязать к категории',
          icon: 'symlink',
        },
        {
          name: 'Пересчитать прогресс товаров',
          icon: 'refresh',
        },
        {
          name: 'Создать отчет по атрибутам',
          icon: 'visPie',
        },
        {
          name: 'Создать отчет по ошибкам',
          icon: 'visLine',
        },
      ],
    },
  ]

  return (
    <>
      <ContextMenu menuButtonText="Действия" panels={panels} endIcon="arrowDown" />
      <AddAttribute isOpen={isAddAttributeModalOpen} onClose={closeAddAttributeModal} />
    </>
  )
}

export { AttributeActions }
