import { EuiPageSection } from '@elastic/eui'
import { attributeGroupsColumns, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { Table, useSortTable } from '@shared/ui/table'
import { useNavigate } from 'react-router-dom'

export const AttributeGroupsTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<AttributeGroup>('order')
  const navigate = useNavigate()

  const { isLoading, data } = useAttributeGroupsList()

  const getRowProps = ({ href }: AttributeGroup) => {
    return {
      onClick: () => {
        navigate(href)
      },
    }
  }

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={attributeGroupsColumns}
        items={sortList(data)}
        loading={isLoading}
        sorting={sorting}
        onChange={onTableChange}
        rowProps={getRowProps}
      />
    </EuiPageSection>
  )
}
