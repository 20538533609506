import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUP_COLUMNS } from '@entities/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useParams } from 'react-router-dom'

export const AttributeGroupTable: ReactFc = () => {
  const { id: attributeGroupId } = useParams()
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttribute>()
  const { selection } = useSelectionTable<ApiAttribute>()

  const { isLoading, data: attributes } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
    object_group: attributeGroupId ? parseInt(attributeGroupId) : undefined,
  })

  const attributeList = attributes?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={ATTRIBUTE_GROUP_COLUMNS}
        items={sortList(attributeList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
