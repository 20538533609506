import { useSkuListApi } from '@entities/catalog'
import { isCatalogView, useFilterSkuList } from '@features/catalog'
import { CATALOG_VIEW } from '@shared/config'

import { CatalogCards } from './catalog-cards'
import { CatalogTable } from './catalog-table'

export const Catalog: ReactFc = () => {
  const { isLoading, data: skuList = [] } = useSkuListApi()
  const filteredSkuList = useFilterSkuList(skuList)

  if (isCatalogView(CATALOG_VIEW.CARDS)) {
    return <CatalogCards skuList={filteredSkuList} isLoading={isLoading} />
  }

  return <CatalogTable skuList={filteredSkuList} isLoading={isLoading} />
}
